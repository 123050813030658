@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  /* display: flex;
  flex: 1;
  justify-content: center;
  align-items: center; */
  /* height: 100vh; */
  /* font: 400 14px Roboto, sans-serif; */
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

Container {
  display: flex;
  width: auto;
  background-color: aqua;
}

